












































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
@Component
export default class InsCmsList extends Vue {
  @Prop({ default: '' }) private catKey!: string; // key值
  private Page: number = 1;
  private PageSize: number | null = 0;
  contents: object[] = [];
  PromotionTitle: object[] = [];

  swiperOption: object = {
    slidesPerView: 3,
    spaceBetween: 60,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiperList-button-next',
      prevEl: '.swiperList-button-prev'
    },
    // autoHeight: true, // 高度随内容变化
    observer: true, // 修改swiper自己或子元素时，自动初始化swiper
    observeParents: true // 修改swiper的父元素时，自动初始化swiper
  };

  // 获取关于cms内容列表
  getContents () {
    if (this.isMobile) {
      this.PageSize = 3;
    } else {
      this.PageSize = null;
    }
    var page = 'Home';
    this.$Api.promotion.getPromotion(page, 1, this.isMobile).then(result => {
      console.log(result, 'getPromotion');
      if (result.Promotion.BannerList.length > 0) {
        this.PromotionTitle = result.Promotion;
        this.contents = result.Promotion.BannerList;
      }
    });
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }

  mounted () {
    this.getContents();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.getContents();
  }
}
